import { createAction } from '@reduxjs/toolkit';

import { SelectedItem } from '../../types/selectedItem';
import { Tapp } from '../../types/tapp';

import {
    SET_ACTIVE_DAVID_SITE_ID,
    SET_ACTIVE_TAPP,
    SET_APP_STATE,
    SET_BOUNDINGS,
    SET_IS_CHAYNS_READY,
    SET_IS_CLIENT,
    SET_IS_MENU_SHOWN,
    SET_IS_SCANNER_SHOWN,
    SET_IS_SMART_CLIENT_AVAILABLE,
    SET_IS_USER_AUTHENTICATED,
    SET_IS_TAPPS_SCROLLED,
    SET_IS_CHAYNS_ICON_IN_HEADER,
    SET_SELECTED_CONTENT_TYPE,
    SET_SELECTED_ITEM,
    SET_COLOR_MODE,
    SET_IS_SEARCHING_MESSAGES,
    SET_MESSAGE_SEARCH_STRING,
    SET_DAVID_DIALOG_COLOR,
    SET_GEO_COORDINATES,
    SET_GEO_POSITION_AVAILABLE,
    RESET_STATE,
    SET_ACTIVE_SMART_CLIENT_TAPP,
    SET_LEFT_BAR_ACTIVE,
    SET_IFRAME_POINTER_EVENTS,
    SET_LOGIN_OVERLAY,
    LOAD_CALENDAR_STORE,
    LOAD_SMARTCLIENT_STORE,
    LOAD_INTERCOM_WEBSOCKET,
    SET_TOOLBAR_HEIGHT,
    SET_HOT_CARD_HEIGHT,
    CLEAR_VR_LOGIN,
    SET_SHOW_TRANSITIONING_OVERLAY, SET_IS_CHAYNS_JS_LOADED, SET_SAFE_AREAS, SET_HAS_SERVER_MINIMUM_VERSION,
} from './types';

export interface SafeAreas {
    top: number,
    right: number,
    bottom: number,
    left: number,
}

export type App = {
    width: number,
    height: number,
    activeTapp: number,
    color: string,
    colorMode: 'dark' | 'light',
    davidDialogTitleColor: string | null,
    isUserAuthenticated: boolean,
    isSmartClientAvailable: boolean,
    isChaynsReady: boolean,
    isClient: boolean,
    isScannerShown: boolean,
    activeDavidSiteId: string | null,
    allowStagingSwitch: boolean,
    isMenuShown: boolean,
    sitesBackgroundColor: string | null,
    isTappScrolled: boolean,
    hideOtherContent: boolean,
    isChaynsIconInHeader: boolean,
    selectedContentType: string,
    selectedItem?: SelectedItem,
    params?: {
        [key: string]: string
    },
    isSearchingMessages: boolean,
    messageSearchString: string | null,
    activeSmartClientTapp: string,
    siteTapps: Array<Tapp>,
    geoPositionAvailable?: boolean,
    geoCoordinates?: { longitude: number, latitude: number, geoPivot?: number },
    leftBarActive: boolean,
    iframePointerEvents: boolean,
    loadCalendarStore: boolean,
    loadSmartClientStore: boolean,
    loadIntercomWebsocket: boolean,
    toolbarHeight: number | undefined,
    hotCardHeight: number | undefined,
    showTransitioningOverlay: boolean,
    loginOverlay?: LoginOverlay,
    isChaynsJsLoaded?: boolean,
    safeAreas: SafeAreas,
    hasServerMinimumVersion: boolean
};

export type LoginOverlay = {
    tokenType: number | string,
}

export const setAppState = createAction<App>(SET_APP_STATE);
export const setIsClient = createAction<boolean>(SET_IS_CLIENT);
export const setIsChaynsReady = createAction<boolean>(SET_IS_CHAYNS_READY);
export const setIsScannerShown = createAction<boolean>(SET_IS_SCANNER_SHOWN);
export const setActiveDavidSiteId = createAction<string>(SET_ACTIVE_DAVID_SITE_ID);
export const setIsMenuShown = createAction<boolean>(SET_IS_MENU_SHOWN);
export const setActiveTapp = createAction<number>(SET_ACTIVE_TAPP);
export const setIsUserAuthenticated = createAction<boolean>(SET_IS_USER_AUTHENTICATED);
export const setIsSmartClientAvailable = createAction<boolean>(SET_IS_SMART_CLIENT_AVAILABLE);
export const setHasServerMinimumVersion = createAction<boolean>(SET_HAS_SERVER_MINIMUM_VERSION);
export const setBoundings = createAction<{ width: number, height: number }>(SET_BOUNDINGS);
export const setIsTappScrolled = createAction<boolean>(SET_IS_TAPPS_SCROLLED);
export const setIsChaynsIconInHeader = createAction<boolean>(SET_IS_CHAYNS_ICON_IN_HEADER);
export const setSelectedContentType = createAction<string>(SET_SELECTED_CONTENT_TYPE);
export const setSelectedItem = createAction<SelectedItem>(SET_SELECTED_ITEM);
export const setColorMode = createAction<'dark' | 'light'>(SET_COLOR_MODE);
export const setIsSearchingMessages = createAction<boolean>(SET_IS_SEARCHING_MESSAGES);
export const setMessageSearchString = createAction<string>(SET_MESSAGE_SEARCH_STRING);
export const setDavidDialogColor = createAction<string>(SET_DAVID_DIALOG_COLOR);
export const setGeoCoordinates = createAction<{ longitude: number, latitude: number, geoPivot?: number }>(SET_GEO_COORDINATES);
export const setGeoPositionAvailable = createAction<unknown>(SET_GEO_POSITION_AVAILABLE);
export const setActiveSmartClientTapp = createAction<string>(SET_ACTIVE_SMART_CLIENT_TAPP);
export const setLoginOverlay = createAction<boolean | LoginOverlay>(SET_LOGIN_OVERLAY);

export const setLeftBarActive = createAction<boolean>(SET_LEFT_BAR_ACTIVE);

export const setIFramePointerEvents = createAction<boolean>(SET_IFRAME_POINTER_EVENTS);

export const resetState = createAction(RESET_STATE);
export const loadCalendarStore = createAction(LOAD_CALENDAR_STORE);
export const loadSmartClientStore = createAction(LOAD_SMARTCLIENT_STORE);
export const loadIntercomWebsocket = createAction(LOAD_INTERCOM_WEBSOCKET);
export const setToolbarHeight = createAction(SET_TOOLBAR_HEIGHT);
export const setHotCardHeight = createAction(SET_HOT_CARD_HEIGHT);

export const clearVrLogin = createAction(CLEAR_VR_LOGIN);
export const setShowTransitioningOverlay = createAction<boolean>(SET_SHOW_TRANSITIONING_OVERLAY);
export const setIsChaynsJsLoaded = createAction(SET_IS_CHAYNS_JS_LOADED);
export const setSafeAreas = createAction<Partial<SafeAreas>>(SET_SAFE_AREAS);
